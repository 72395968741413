import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAnchor.figmaUrl.ios} codeUrl={checklists.componentAnchor.codeUrl.ios} checklists={checklists.componentAnchor.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Anchor text is text that can be clicked and hyperlinked with other sites or pages. The use of anchor text is very important in the world of content marketing because it can increase index and ranking on search engines.`}</p>
    <p>{`Use Legion Anchor text styles to modify Anchor text with lots of attributes that make you easier.`}</p>
    <p>{`ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText();
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p><inlineCode parentName="p">{`LGNAnchorText`}</inlineCode>{` with the following appearances:`}</p>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Label`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "2px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/icon.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Icon`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "2px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/label.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Icon Left + Label`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "2px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/iconlabel.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Label + Icon Right`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "2px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/labelicon.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Icon Left + Label + Icon Right`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "2px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/iconlabelicon.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Use this Function : `}<inlineCode parentName="p">{`LGNAnchorText`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify the variant of Anchor text :`}</p>
    <p><strong parentName="p">{` A. Label `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label"
)
`}</code></pre>
    <p><strong parentName="p">{` B. Icon `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    centerImage: Image(systemName: "plus")
)
`}</code></pre>
    <p><strong parentName="p">{` C. Icon Left + Label `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label",
    leftImage: Image(systemName: "plus")
)
`}</code></pre>
    <p><strong parentName="p">{` D. Label + Icon Right `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label",
    rightImage: Image(systemName: "plus")
)
`}</code></pre>
    <p><strong parentName="p">{` E. Icon Left + Label + Icon Right `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label",
    leftImage: Image(systemName: "plus"),
    rightImage: Image(systemName: "chevron.down")
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`Legion Have 1 Attribute for Customize Anchor text :`}</p>
    <h3><strong parentName="h3">{` Size `}</strong></h3>
    <p>{`This size attribute for user to costumize size on the Anchor text`}</p>
    <h3><strong parentName="h3">{` Small `}</strong></h3>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Label`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/labelsmall.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Icon`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/iconsmall.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Icon Left + Label`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/iconlabelsmall.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Label + Icon Right`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/labeliconsmall.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Icon Left + Label + Icon Right`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/iconlabeliconsmall.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Use this Function : `}<inlineCode parentName="p">{`LGNAnchorText`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify the size of Achor text :`}</p>
    <p><strong parentName="p">{` A. Label `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label"
)
.variant(size: .small)
`}</code></pre>
    <p><strong parentName="p">{` B. Icon `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    centerImage: Image(systemName: "plus")
)
.variant(size: .small)
`}</code></pre>
    <p><strong parentName="p">{` C. Icon Left + Label `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label",
    leftImage: Image(systemName: "plus")
)
.variant(size: .small)
`}</code></pre>
    <p><strong parentName="p">{` D. Label + Icon Right `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label",
    rightImage: Image(systemName: "plus")
)
.variant(size: .small)
`}</code></pre>
    <p><strong parentName="p">{` E. Icon Left + Label + Icon Right `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label",
    leftImage: Image(systemName: "plus"),
    rightImage: Image(systemName: "chevron.down")
)
.variant(size: .small)
`}</code></pre>
    <h3><strong parentName="h3">{` Normal `}</strong></h3>
    <div {...{
      "className": "wrapper-display-component-three",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Label`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/labelnormal.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Icon`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/iconnormal.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Icon Left + Label`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/iconlabelnormal.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Label + Icon Right`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/labeliconnormal.png"
          }}></img>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Icon Left + Label + Icon Right`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "5px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/anchor/iconlabeliconnormal.png"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <p>{`Use this Function : `}<inlineCode parentName="p">{`LGNAnchorText`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify the size of Achor text :`}</p>
    <p><strong parentName="p">{` A. Label `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label"
)
.variant(size: .normal)
`}</code></pre>
    <p><strong parentName="p">{` B. Icon `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    centerImage: Image(systemName: "plus")
)
.variant(size: .normal)
`}</code></pre>
    <p><strong parentName="p">{` C. Icon Left + Label `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label",
    leftImage: Image(systemName: "plus")
)
.variant(size: .normal)
`}</code></pre>
    <p><strong parentName="p">{` D. Label + Icon Right `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label",
    rightImage: Image(systemName: "plus")
)
.variant(size: .normal)
`}</code></pre>
    <p><strong parentName="p">{` E. Icon Left + Label + Icon Right `}</strong></p>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
    title: "Label",
    leftImage: Image(systemName: "plus"),
    rightImage: Image(systemName: "chevron.down")
)
.variant(size: .normal)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <h3><strong parentName="h3">{` With Title `}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Properties`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Default Value`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the main text string you want to display on anchor text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not have`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily of your title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftImage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the Image() you want to display on the left side of your title text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rightImage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the Image() you want to display on the right side of your title text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tintColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color tint for your anchor text: title, left image, and right image`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.primary500`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action to perform when the user pressed the anchor text.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h3><strong parentName="h3">{` With centerImage (Without Title) `}</strong></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Properties`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Default Value`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`centerImage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the only Image() you want to display on the center side of the anchor text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`not have`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tintColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color tint for your center image`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.primary500`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action to perform when the user pressed the anchor text.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Example Project`}</h2>
    <p>{`Theme (ThemeLGN, ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNAnchorText(
  centerImage: Image(systemName: "plus")
) {
  print("Hello")
}

LGNAnchorText(
  title: "Label",
  leftImage: Image(systemName: "plus"),
  rightImage: Image(systemName: "chevron.down")
){
  print("Hello")
}
.setLeftImage(width: 9.33, height: 9.33, aspectRatio: .fill)
.setRightImage(width: 16, height: 7, aspectRatio: .fill)
.setCenterImage(width: 9.33, height: 9.33, aspectRatio: .fit)
.variant(size: .normal)
.setFocused(true)
.showProgressView(true)
.disableInteraction(false)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      